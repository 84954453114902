<template>
  <div class="nav">
    <header @click="handleClose" class="new_header">
      <div class="header_content relative">
        <div class="header_top">
          <div class="left">
            <router-link to="/" title="中国残联就业创业服务机构">
              <img src="~/static/home/logo.png" alt="" class="logo">
            </router-link>
            <div class="location" @click="citys = true">
              <img src="~/static/home/location.png" alt="位置：">
              <span class="location_span">
              {{ location }}
              </span>
              <a href="javascript:void(0);" class="select">切换城市</a>
            </div>
          </div>
          <ul class="right">
            <li class="cursorP" @click="canyou">
              <img src="~/static/home/lun.png" alt="" aria-hidden="true">
              无障碍浏览
            </li>
            <li @click="messageHandle" class="cursorP">
              <el-badge
                  is-dot
                  class="item"
                  v-if="count"
              >
                <img src="~/static/home/msg.png" alt="消息">
                消息
              </el-badge>
              <el-badge
                  is-dot
                  class="item"
                  :hidden="true"
                  v-else
              >
                <img src="~/static/home/msg.png" alt="消息">
                消息
              </el-badge>
            </li>
            <li @mouseover="loginAlert = true" v-if="!user.name">
              <div class="login flex">
                登录/注册
              </div>
            </li>
            <li v-else class="flex">
              <div class="login_avatar">
                <img :src="user.logo" alt class="imgs err_avatar"/>
              </div>
              <HeadDrop></HeadDrop>
            </li>
          </ul>
        </div>
        <div class="header_bottom" v-if="isHide">
          <div class="left">
            <el-menu
                :default-active="activeIndex"
                class="el-menu-demo tab_el_memu"
                mode="horizontal"
                :router="true"
                @select="handleSelect"
                v-show="user.type == 1 || user.type == 4"
            >
              <el-menu-item index="/">
                <div class="li">首页</div>
              </el-menu-item>
              <el-menu-item index="/job/"
              >
                <div class="li">求职招聘</div>
              </el-menu-item
              >
              <el-menu-item index="/training/"
              >
                <div class="li">职业培训</div>
              </el-menu-item
              >
              <el-menu-item index="/asklib/"
              >
                <div class="li">问答</div>
              </el-menu-item
              >
              <el-menu-item index="/section/"
              >
                <div class="li">专栏</div>
              </el-menu-item
              >
              <el-menu-item index="/news/"
              >
                <div class="li">政策要闻</div>
              </el-menu-item
              >
            </el-menu>
            <el-menu
                :default-active="activeIndex"
                class="el-menu-demo tab_el_memu"
                mode="horizontal"
                :router="true"
                @select="handleSelect"
                v-show="user.type == 2"
            >
              <el-menu-item index="/enterprise">
                <div class="li">首页</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/post">
                <div class="li">职位管理</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/recruiting">
                <div class="li">找人才</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/netJob">
                <div class="li">网络招聘会</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/ask">
                <div class="li">问答社区</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/section">
                <div class="li">专栏</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/wiki">
                <div class="li">残疾人百科</div>
              </el-menu-item>
              <!--            <el-menu-item index="/enterprise/3">商城</el-menu-item>-->
            </el-menu>
          </div>
          <div class="right">
            <el-input placeholder="请输入想要搜索的内容" title="请输入想要搜索的内容" v-model="search.name" class="search">
              <el-select v-model="search.select" slot="prepend" placeholder="请选择" aria-label="下拉列表框">
                <el-option label="职位" value="1"></el-option>
                <el-option label="公司" value="2"></el-option>
              </el-select>
              <el-button slot="append" icon="el-icon-search" title="搜索"
                         @click="goRouters('/job/?active='+search.name)"></el-button>
            </el-input>
          </div>
        </div>
        <div class="weimu" v-show="loginAlert" title="登录/注册" @click="loginAlert = false">
        </div>
        <div class="login_alert" v-show="loginAlert">
          <el-button type="primary" @click="goRouters('/Login/')"
          ><img src="~/static/login/user1.png" alt=""/>我要求职
          </el-button
          >
          <el-button
              type="primary"
              class="blue"
              @click="goRouters('/user/companyLogin')"
          ><img src="~/static/login/company1.png" alt=""/>我要招聘
          </el-button
          >
          <a
              href="https://admin.cdpee.org.cn/"
              target="_blank"
              @click="loginAlert = false"
          >
            <el-button type="waring" class="org"
            ><img src="~/static/login/jigou1.png" alt=""/>机构登录
            </el-button
            >
          </a>
        </div>
      </div>
    </header>
    <header @click="handleClose" class="fixed" :style="{display:display}">
      <div class="header_content relative">
        <div class="header_bottom">
          <router-link to="/" title="中国残联就业创业服务机构">
            <img src="~/static/u53.png" alt="" class="logo">
          </router-link>
          <div class="left">
            <el-menu
                :default-active="activeIndex"
                class="el-menu-demo tab_el_memu"
                mode="horizontal"
                :router="true"
                @select="handleSelect"
                v-show="user.type == 1 || user.type == 4"
            >
              <el-menu-item index="/"
              >
                <div class="li">首页</div>
              </el-menu-item
              >
              <el-menu-item index="/job/"
              >
                <div class="li">求职招聘</div>
              </el-menu-item
              >
              <el-menu-item index="/training/"
              >
                <div class="li">职业培训</div>
              </el-menu-item
              >
              <el-menu-item index="/asklib/"
              >
                <div class="li">问答</div>
              </el-menu-item
              >
              <el-menu-item index="/section/"
              >
                <div class="li">专栏</div>
              </el-menu-item
              >
              <el-menu-item index="/news/"
              >
                <div class="li">政策要闻</div>
              </el-menu-item
              >
            </el-menu>
            <el-menu
                :default-active="activeIndex"
                class="el-menu-demo tab_el_memu"
                mode="horizontal"
                :router="true"
                @select="handleSelect"
                v-show="user.type == 2"
            >
              <el-menu-item index="/enterprise">
                <div class="li">首页</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/post">
                <div class="li">职位管理</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/recruiting">
                <div class="li">找人才</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/netJob">
                <div class="li">网络招聘会</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/ask">
                <div class="li">问答社区</div>
              </el-menu-item>
              <el-menu-item index="/enterprise/section">
                <div class="li">专栏</div>
              </el-menu-item>
              <!--            <el-menu-item index="/enterprise/3">商城</el-menu-item>-->
            </el-menu>
          </div>
          <ul class="right">
            <li @click="canyou">
              <img src="~/static/home/lun.png" alt="">
            </li>
            <li @click="messageHandle">
              <el-badge :value="count" class="item">
                            <img src="~/static/home/msg.png" alt="">
              </el-badge>
            </li>
            <li>
              <img :src="!isShow?require('@/static/home/search.png'):require('@/static/home/search_green.png')" alt=""
                   @mousemove="isShow = true">
            </li>
            <li @mouseover="loginAlert = true" v-if="!user.name">
              <div class="login flex">
                登录/注册
              </div>
            </li>
            <li v-else class="flex">
              <div class="login_avatar">
                <img :src="user.logo" alt class="imgs err_avatar"/>
              </div>
              <HeadDrop></HeadDrop>
            </li>
          </ul>
        </div>
        <div class="search_content" v-show="isShow">
          <el-input placeholder="请输入想要搜索的内容" v-model="search.name" class="search" @click.stop="isShow = true">
            <el-select v-model="search.select" slot="prepend" placeholder="请选择">
              <el-option label="职位" value="1"></el-option>
              <el-option label="公司" value="2"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="goRouters('/job/?active='+search.name)"></el-button>
          </el-input>
        </div>
        <div class="weimu" v-show="loginAlert" @click="loginAlert = false">
        </div>
        <div class="login_alert" v-show="loginAlert">
          <el-button type="primary" @click="goRouters('/Login/')"
          ><img src="~/static/login/user1.png" alt=""/>我要求职
          </el-button
          >
          <el-button
              type="primary"
              class="blue"
              @click="goRouters('/user/companyLogin')"
          ><img src="~/static/login/company1.png" alt=""/>我要招聘
          </el-button
          >
          <a
              href="https://admin.cdpee.org.cn/"
              target="_blank"
              @click="loginAlert = false"
          >
            <el-button type="waring" class="org"
            ><img src="~/static/login/jigou1.png" alt=""/>机构登录
            </el-button
            >
          </a>
        </div>
      </div>
    </header>
    <city :show="citys" width="1000px" @close="citys = false"></city>
    <disLogin
        :goShow="isHideLogin"
        @close="handleClose"
        title="残疾人登录"
        :text="title"
    ></disLogin>
    <message :show='isMessage' @change="change($event)"></message>
  </div>
</template>

<script>
import city from "@/components/search/allCity";
import HeadDrop from "@/components/public/HeadDrop";
import disLogin from "@/components/login/disLogin";
import message from "@/components/public/message";

export default {
  name: "newHeader",
  props: {
    index: "",
  },
  inject: ["reload"],
  components: {
    city,
    HeadDrop,
    disLogin,
    message
  },
  data() {
    return {
      location: '北京市',
      activeIndex: this.$route.path || "/",
      search: {
        name: '',
        select: '1',
      },
      loginAlert: false,
      isHideLogin: false,
      citys: false,
      title: '',
      user: {
        type: '1',
        logo: '',
        name: '',
      },
      isHide: true,
      isShow: false,
      isTop: '0',
      isMessage: false,
      count:'',
      display:'none'
    }
  },
  methods: {
    change(){
      this.isMessage = false;
    },
    goRouters(url) {
      this.$router.push(url);
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
    messageHandle() {
      this.isMessage = true;
    },
    handleClose() {
      this.isHideLogin = false;
      this.loginAlert = false;
      this.isShow = false;
    },
    //无障碍
    canyou() {
      window.toolbar.open();
      this.isTop = '88';
    },
    //通知消息
    async message() {
      this.$api
          .messageApi({pageSize: 5})
          .then((res) => {
            if (res.data) {
              this.no_message = 1;
              this.messageList = res.data.data;
              this.$api.queryUserMsgCount().then((res) => {
                if(res.data.zong != 0){
                  this.count = res.data.zong;
                }
              });
            } else {
              this.no_message = 0;
            }
          })
          .catch((err) => {
            this.no_message = 0;
          });
    },
    handleScroll() {
      var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      //  超low的滚动定位效果
      if (scrollTop > 100) {
        this.display = 'block';
      } else {
        this.display = 'none';
      }
    },
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    if (this.$store.state.userType || localStorage.getItem('userInfo')) {
      let user = this.$store.state.userType || JSON.parse(localStorage.getItem('userInfo'));
      this.user.type = user.userType || 1;
      this.user.logo = user.logo || '';
      this.user.name = user.userName;
      this.message();
    }
    setTimeout(() => {
      this.activeIndex = this.index || this.$route.path;
    }, 10);
    let text = localStorage.getItem("locationText") || "全国站";
    let locationSelect = localStorage.getItem("locationSelect");
    if (locationSelect) {
      this.location = locationSelect;
    } else {
      this.location = text;
    }
  }
}
</script>

<style scoped lang="less">
.new_header{
  z-index:11111;
}
header {
  background: white url("~static/home/header_bg.png") center top no-repeat;
  background-size: 100% 100%;

  .header_content {
    width: 1200px;
    padding: 15px 0;
    margin: 0 auto;

    .header_top {
      position: relative;
      display: flex;
      align-items: center;
      padding-bottom: 14px;
      border-bottom: 1px solid rgba(0, 18, 10, 0.04);

      .left {
        display: flex;
        flex: 1;

        .logo {
          height: 48px;
          vertical-align: middle;
        }

        .location {
          margin-left: 12px;
          font-size: 16px;
          color: #66716C;
          margin-top: 12px;

          img {
            vertical-align: middle;
            transform: translateY(-2px) translateX(6px);
          }

          a {
            color: #0077BB;
            font-size: 14px;
          }
        }
      }

      .right {
        display: flex;

        li {
          margin-left: 24px;
          font-size: 16px;
          color: #66716C;

          img {
            vertical-align: middle;
          }

          .login {
            padding: 0 5px;
            font-size: 14px;
            color: #00A868;
            border: 1px solid #00A868;
            border-radius: 6px;
            cursor: pointer;
          }

          .login_avatar {
            .imgs {
              width: 22px;
              height: 22px;
              margin-right: 10px;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .header_bottom {
      display: flex;
      padding-top: 18px;

      .left {
        flex: 1;

        .el-menu {
          border: none;
          background: transparent;

          .el-menu-item {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 108px;
            height: 42px;
            margin-right: 24px;
            line-height: inherit;
            background: rgba(0, 18, 10, 0.02);
            border-radius: 21px;
            color: #66716C;
            font-size: 16px;
          }

          .el-menu-item:hover {
            background: rgba(40, 164, 109, 0.12);
            color: #28A46D;
          }

          .is-active:hover {
            color: white;
            background: #28A46D;
          }

          .is-active {
            border: none;
            color: white;
            background: #28A46D;
            box-shadow: 0px 6px 12px 0px rgba(40, 164, 109, 0.24);
          }

        }
      }

      .right {
        /deep/ .search {
          width: 252px;
          border-radius: 21px;
          border: 1px solid rgba(0, 18, 10, 0.1);

          .el-input-group__prepend {
            width: 78px;
            border: none;
            border-top-left-radius: 21px;
            border-bottom-left-radius: 21px;
            background: none;

            .el-select {
              position: relative;
              font-size: 16px;
              color: #28A46D;

              .el-input__suffix {
                .el-input__suffix-inner {
                  color: #28A46D;

                  .el-select__caret {
                    color: #28A46D;
                  }
                }
              }
            }

            .el-select::after {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 1px;
              height: 18px;
              background: rgba(0, 18, 10, 0.1);
            }
          }

          .el-input__inner {
            border: none;
            background: none;
          }

          .el-input-group__append {
            border: none;
            background: none;

            .el-button--default {
              .el-icon-search {
                color: #66716C;
                font-size: 16px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

.login_alert {
  position: absolute;
  right: 0;
  width: 306px;
  padding: 30px;
  background: white;
  border-radius: 0px 0px 6px 6px;
  z-index: 10000;

  .el-button {
    width: 246px;
    height: 42px;
    line-height: 42px;
    padding: 0;
    margin-left: 0;
    margin-bottom: 24px;
    border: none;

    img {
      width: 18px !important;
      height: 18px !important;
      vertical-align: middle;
      transform: translateY(-1px);
      margin-right: 5px;
    }
  }

  .blue {
    background: #0090e2;
  }

  .org {
    margin-bottom: 0;
    color: white;
    background: #ff9600;
  }
}

.weimu {
  position: sticky;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 999;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 12px 0px rgba(0, 18, 10, 0.03);
  //filter: blur(10px);
  z-index: 100;

  .header_content .header_bottom {
    padding-top: 0;
    .logo {
      height: 42px;
      margin-right: 33px;
    }

    .right {
      display: flex;
      align-items: center;

      li {
        margin-left: 24px;
        font-size: 16px;
        color: #66716C;

        img {
          vertical-align: middle;
          cursor: pointer;
        }

        .login {
          padding: 0 5px;
          font-size: 14px;
          color: #00A868;
          border: 1px solid #00A868;
          border-radius: 6px;
          cursor: pointer;
        }

        .login_avatar {
          .imgs {
            width: 22px;
            height: 22px;
            margin-right: 10px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .search_content {
    padding-top: 17px;
    margin-top: 20px;
    border-top: 1px solid rgba(0, 18, 10, 0.05);
    text-align: center;

    /deep/ .search {
      width: 792px;
      margin: 0 auto;
      background: white;
      border-radius: 27px;
      box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.03);

      .el-input-group__prepend {
        width: 78px;
        border: none;
        border-top-left-radius: 21px;
        border-bottom-left-radius: 21px;
        background: none;

        .el-select {
          position: relative;
          font-size: 16px;
          color: #28A46D;

          .el-input__suffix {
            .el-input__suffix-inner {
              color: #28A46D;

              .el-select__caret {
                color: #28A46D;
              }
            }
          }
        }

        .el-select::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 18px;
          background: rgba(0, 18, 10, 0.1);
        }
      }

      .el-input__inner {
        border: none;
        background: none;
      }

      .el-input-group__append {
        border: none;
        background: none;

        .el-button--default {
          .el-icon-search {
            color: #66716C;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

header .header_content .header_bottom .right[data-v-4e311e92] .search .el-input-group__prepend .el-select {
  margin: -10px -28px;
  padding-left: 17px;
}

.location_span {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #66716C;
  line-height: 24px;
}
</style>