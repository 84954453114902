<template>

  <el-dialog
      :visible.sync="show"
      :modal-append-to-body="false"
      width="370px"
      :show-close="false"
      :before-close="close">
    <div class="message" v-if="messageList">
      <ul class="tab">
        <li class="cursorP" :class="active == 0?'active':''"
            @click="tabs(0)">
        <el-badge :value="count.touDi" class="item">
        </el-badge>
          投递信息
        </li>
        <li class="cursorP" :class="active == 1?'active':''"
            @click="tabs(1)">
        <el-badge :value="count.yaoQing" class="item">
        </el-badge>
          面试邀请
        </li>
        <li class="cursorP" :class="active == 2?'active':''"
            @click="tabs(2)">
        <el-badge :value="count.xiTong" class="item">
        </el-badge>
          系统通知
        </li>
      </ul>
      <div class="no_message" v-if="noMessage">
        <img src="~/static/message/no_message.png" alt="">
      </div>
      <div class="box" v-else>
        <ul class="list" v-show="active === 0 && messageList.touDi.length > 0">
          <li v-for="item in messageList.touDi" :key="item.id" class="line_clamp1" :title="item.noticeMsgJson.msgJson ">
           <el-badge dge is-dot class="item"  v-if="item.isSee == 0"></el-badge>
            <router-link
              :to="item.path ? item.path : ''"
              targe="_blank" @click.native="changeStatusById(item.id)"
              >{{ item.noticeMsgJson.msgJson }}
            </router-link>
          </li>
        </ul>
        <ul class="list" v-show="active === 1 && messageList.yaoQing.length > 0">
          <li v-for="item in messageList.yaoQing" :key="item.id" class="line_clamp1" :title="item.noticeMsgJson.msgJson ">
           <el-badge dge is-dot class="item"  v-if="item.isSee == 0"></el-badge>
            <router-link
              :to="item.path ? item.path : ''"
              targe="_blank" @click.native="changeStatusById(item.id)"
              >{{ item.noticeMsgJson.msgJson }}
            </router-link>
          </li>
        </ul>
        <ul class="list" v-show="active === 2 && messageList.xiTong.length > 0">
          <li v-for="item in messageList.xiTong" :key="item.id" class="line_clamp1" :title="item.noticeMsgJson.msgJson ">
           <el-badge dge is-dot class="item"  v-if="item.isSee == 0"></el-badge>
            <router-link
              :to="item.path ? item.path : ''"
              targe="_blank" @click.native="changeStatusById(item.id)"
              >{{ item.noticeMsgJson.msgJson }}
            </router-link>
          </li>
        </ul>
        <div class="no_message" v-show="active === 0 && messageList.touDi.length <= 0">
          <img src="~/static/message/no_message.png" alt="">
        </div>
        <div class="no_message" v-show="active === 1 && messageList.yaoQing.length <= 0">
          <img src="~/static/message/no_message.png" alt="">
        </div>
        <div class="no_message" v-show="active === 2 && messageList.xiTong.length <= 0">
          <img src="~/static/message/no_message.png" alt="">
        </div>
        <div class="bottom">
          <div class="read" @click="read">
            全部标记为已读
          </div>
          <div class="more" @click="goRouter('/messages/'),close()">
            更多内容
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "message",
  props: ["show"],
  data() {
    return {
      messageList: {
        touDi: [],
        yaoQing: [],
        xiTong: []
      },
      noMessage: false,
      count: '',
      status: 0,
      tab: ["投递反馈", "面试邀请", "系统通知"],
      active: 0,
    }
  },
  methods: {
    tabs(i) {
      this.active = i;
    },
    //单条已读
    changeStatusById(val) {
      this.$api.changeReadStatusById({id: val});
      this.message();
      this.$parent.message();
      this.close();
    },
    //通知消息
    async message() {
      this.$api
          .messageApi({pageSize: 8})
          .then((res) => {
            if (res.data) {
              let data = res.data.data;
              this.messageList.touDi = data.touDi;
              this.messageList.yaoQing = data.yaoQing;
              this.messageList.xiTong = data.xiTong;
              this.$api.queryUserMsgCount().then((red) => {
                if (red.data.success == false) {
                  this.noMessage = true;
                  return
                }
                this.count = red.data;
                this.$emit('change');
              });
            } else {
              // this.no_message = 0;
            }
          })
          .catch((err) => {
            this.no_message = 0;
          });
    },
    //一键已读
    read() {
      this.$api.readsApi("all").then((res) => {
        if (res.data.success) {
          this.$message.success("刷新成功！！");
        }
        this.message();
      });
    },
    goRouter(url) {
      this.$router.push(url);
    },
    close() {
      this.$emit('change', {show: false});
    },
  },
  created() {
    this.message();
    // this.read();
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog{
  background: transparent;
  position: absolute;
  top: 0;
  right: 56.5%;
  transform: translateX(200%);
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
}
.message {
  width: 370px;
  height: 352px;

  background: url("../../static/message/borders.png") center center no-repeat;
  background-size: 370px auto;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  .tab {
    display: flex;
    border-bottom: 1px solid #E1E1E1;

    li {
      flex: 1;
      height: 62px;
      line-height: 72px;
      text-align: center;
      position: relative;
    }

    li:after {
      content: '';
      position: absolute;
      top: 60%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background: #E1E1E1;
    }

    li.active {
      color: #28A46D;
      font-weight: 600;
    }

    li:last-child:after {
      display: none;
    }
  }

  .no_message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box {
    .list {
      padding: 0 15px;
      height: 250px;

      li {
        font-size: 13px;
        // display: flex;
        max-height: 50px;
        line-height: 27px;
        border-bottom: 1px solid #E1E1E1;
        align-items: center;
      }

      li:hover {
        color: #28A46D;
      }

      li:last-child {
        border-bottom: 0;
      }
    }

    .bottom {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      background: #EDFEF1;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .read {
        flex: 1;
        color: #666666;
      }
      .read:hover{
        color:#28A46D;
      }
      .more {
        color: #28A46D;
        cursor: pointer;
      }
    }

    .no_message {
      height: 250px;
    }
  }

  .zhezhao {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: white;
    z-index: 100;
  }
}
</style>